html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Lato Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "RobotoSlab Regular";
  src: url("./assets/fonts/roboto_slab/static/RobotoSlab-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Lato Regular";
  src: url("./assets/fonts/lato/Lato-Regular.ttf") format("truetype");
}
